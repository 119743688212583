<template>
  <!-- loading -->
  <loader-content v-if="loading" />

  <!-- content -->
  <section v-else-if="news.length > 0">
    <v-row
      v-if="$vuetify.breakpoint.mdAndUp"
      justify="center"
      justify-md="start"
      align="start"
    >
      <v-col v-for="(item, i) in news" cols="4" :key="i">
        <NewsCard :news="item" :index="i" />
      </v-col>
    </v-row>

    <v-slide-group v-else show-arrows="always">
      <v-slide-item v-for="(item, i) in news" :key="i">
        <NewsCard :news="item" :width="'250'" :index="i" class="mx-2" />
      </v-slide-item>
    </v-slide-group>
  </section>

  <!-- empty -->
  <div v-else class="d-flex flex-column align-center pb-12">
    <span class="text-body-1 text-center subtext--text">
      Nenhum resultado foi encontrado
    </span>
  </div>
</template>

<script>
import { getNews } from "@/services/news.js";
import { formatDate } from "@/utils";
import NewsCard from "./NewsCard.vue";

export default {
  data() {
    return {
      loading: true,
      news: [],
    };
  },

  components: {
    NewsCard,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: 0,
          size: 3,
        };

        await getNews(payload).then((res) => {
          this.news = res.news;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    formatDate,
  },
};
</script>

<style></style>
