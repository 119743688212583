<template>
  <router-link to="/news">
    <v-card
      color="transparent"
      :width="width"
      :min-width="width"
      :max-width="width"
      flat
    >
      <a class="text-decoration-none">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column mb-4">
            <v-sheet v-if="index % 2 !== 0" height="8" color="primary" />

            <v-card color="accent" tile flat>
              <v-img :src="news.thumb" :aspect-ratio="16 / 9" />
            </v-card>

            <v-sheet v-if="index % 2 === 0" height="8" color="primary" />
          </div>

          <div class="text-body-2 font-weight-bold overtext--text mb-1">
            <span class="saira">{{ formatDate(news.createdAt) }}</span>
          </div>

          <div class="text-h6 font-weight-bold primary--text">
            <span class="saira">
              {{ news.title }}
            </span>
          </div>
        </div>
      </a>
    </v-card>
  </router-link>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  props: {
    news: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      default: 0,
    },

    width: {
      type: String,
      default: "",
    },
  },

  methods: {
    formatDate,
  },
};
</script>

<style></style>
