<template>
  <div>
    <!-- header -->
    <section class="py-16">
      <v-row align="center" justify="center">
        <v-col cols="11" md="5" xl="4">
          <h1 class="text-h4 text-md-h3 font-weight-black mb-6">
            <span class="saira">
              A maior liga estudantil de E-sports da Paraíba!
            </span>
          </h1>

          <div class="mb-6 d-flex align-center">
            <v-divider
              :style="{
                'min-width': '50px',
                'max-width': '50px',
                'background-color': '#DE6771',
              }"
            />
            <span
              class="text-body-1 text-md-h6 font-weight-regular subtext--text ms-4"
            >
              Faça sua inscrição gratuita e venha representar sua Instituição de
              Ensino na maior competição virtual do estado.
            </span>
          </div>

          <v-btn
            class="rounded-l-0 rounded-r-lg text-none"
            color="primary"
            :x-large="$vuetify.breakpoint.mdAndUp"
            @click="$emit('login')"
          >
            <v-icon left>mdi-arrow-right</v-icon>
            Cadastre-se
          </v-btn>
        </v-col>

        <v-col cols="12" md="6" xl="5">
          <v-slide-group show-arrows="always">
            <v-slide-item v-for="(item, i) in modalities" :key="i">
              <v-card
                class="rounded-lg mx-2"
                color="transparent"
                max-width="300px"
                width="300px"
                min-width="200px"
              >
                <v-img :src="item.thumb" :aspect-ratio="3 / 6">
                  <div class="d-flex fill-height align-end">
                    <div class="pl-4 py-2 pr-16 mb-12 opacity rounded-r-lg">
                      <span class="primary--text font-weight-bold">
                        {{ String(item.name).toUpperCase() }}
                      </span>
                    </div>
                  </div>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </section>

    <!-- modalities -->
    <section class="py-16">
      <v-container class="mb-4">
        <div class="d-flex align-center">
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            :src="require('@/assets/utils/divider.png')"
            class="mr-4"
            max-width="10vw"
          />

          <div class="text-h5 text-md-h4 font-weight-black">
            <span class="saira"> MODALIDADES </span>
          </div>
        </div>
      </v-container>

      <div class="d-flex justify-center py-6 altback pb-8">
        <v-slide-group show-arrows="always">
          <v-slide-item v-for="(item, i) in modalities" :key="i" class="py-8">
            <GameCard :game="item" />
          </v-slide-item>
        </v-slide-group>
      </div>
    </section>

    <!-- news -->
    <section class="py-16">
      <v-container class="mb-4">
        <div class="d-flex align-center">
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            :src="require('@/assets/utils/divider.png')"
            class="mr-4"
            max-width="10vw"
          />

          <div class="text-h5 text-md-h4 font-weight-black">
            <span class="saira">ÚLTIMAS NOTÍCIAS </span>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex px-7 px-md-0 justify-end mb-4">
            <v-btn class="mr-md-16" text to="/news">
              Ver todas as Notícias
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <LastNews />
        </div>
      </v-container>
    </section>

    <!-- transmissions -->
    <section class="py-16 surface">
      <v-container class="mb-4">
        <div class="d-flex align-center">
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            :src="require('@/assets/utils/divider.png')"
            class="mr-4"
            max-width="10vw"
          />

          <div class="text-h5 text-md-h4 font-weight-black">
            <span class="saira">TRANSMISSÕES AO VIVO</span>
          </div>
        </div>
      </v-container>

      <div class="d-flex">
        <v-container>
          <v-list-item class="mb-2">
            <v-list-item-avatar color="altback" size="64">
              <v-img :src="require('@/assets/logo/logo.png')" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="mb-2"> AJF eSports </v-list-item-title>
              <v-list-item-subtitle>
                <v-btn
                  color="#9147ff"
                  small
                  href="https://www.twitch.tv/ajfesports"
                  target="_blank"
                >
                  <v-icon left small>mdi-heart</v-icon>

                  Seguir
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <iframe
            src="https://player.twitch.tv/?channel=ajfesports&parent=liga.arenajoguefacil.com.br"
            height="600"
            width="100%"
            allowfullscreen="true"
          >
          </iframe>
        </v-container>
      </div>
    </section>

    <!-- subscription -->
    <section class="py-16">
      <v-container class="mb-4">
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            <div class="d-flex flex-column">
              <div
                class="text-h5 text-md-h4 font-weight-black primary--text mb-12"
              >
                <span class="saira"> INSCRIÇÕES 2022 </span>
              </div>

              <div class="mb-12 text-body-1 text-md-h6 font-weight-regular">
                <p>
                  A Arena Jogue Fácil apresenta a primeira edição da Liga
                  Estudantil Paraibana de E-Sports, que terá inscrições
                  gratuitas.
                </p>

                <p>
                  Para se inscrever basta o aluno estar regularmente inscrito em
                  uma Instituição de Ensino no Estado da Paraíba e possuir idade
                  adequada para a modalidade de jogo escolhida.
                </p>

                <p class="mb-0">
                  A primeira edição da liga contará com os seguintes jogos:
                  FIFA22, Free Fire, Valorant, CS:GO e Clash Royale. Não perca
                  tempo e venha participar da liga mais competitiva do Nordeste;
                </p>
              </div>

              <div class="d-flex">
                <v-sheet class="pa-2 rounded-l-0 rounded-r-lg borderzim">
                  <v-btn
                    class="rounded-l-0 rounded-r-lg text-none"
                    color="primary"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    @click="$emit('login')"
                  >
                    INSCREVA-SE
                  </v-btn>
                </v-sheet>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="5">
            <v-img :src="require('@/assets/home/bottom-art.png')" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameCard from "@/components/games/GameCard.vue";
import LastNews from "@/components/news/LastNews.vue";

export default {
  components: {
    GameCard,
    LastNews,
  },

  computed: {
    ...mapState(["modalities"]),
  },
};
</script>

<style lang="scss" scoped>
.borderzim {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='3' stroke-dasharray='9%2c 7%2c 23' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.opacity {
  background-color: #00000099;
}
</style>
